import { axiosInstance } from '@config/axios-instance-v2';
import { AxiosResponse } from 'axios';

export const tenantService = {
  async getListTenant({ page = 1, perPage = 10, name = '', username = '', dbname = '', domain = '' }) {
    const response: AxiosResponse = await axiosInstance.get(`/tenants`, {
      params: {
        page,
        perPage,
        name,
        username,
        dbname,
        domain,
      },
    });
    return response.data;
  },
  async getDetailTenant(id: string) {
    const response: AxiosResponse = await axiosInstance.get(`/tenants/${id}`, {});
    return response.data;
  },
  async addNewTenant(data) {
    const response: AxiosResponse = await axiosInstance.post(`/tenants`, data);
    return response.data;
  },
  async checkUsername(username) {
    const response: AxiosResponse = await axiosInstance.post(`/tenants/checkUsername`, username);
    return response.data;
  },
  async checkDomain(domain) {
    const response: AxiosResponse = await axiosInstance.post(`/tenants/checkDomain`, domain);
    return response.data;
  },
  async updateTenant(id, data) {
    const response: AxiosResponse = await axiosInstance.patch(`/tenants/${id}`, data);
    return response.data;
  },
};
export type TenantService = typeof tenantService;
