import React from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';

const { Content, Footer } = Layout;

interface ContentLayoutProps {
  BreadcrumbItems?: {
    title: string;
    href: string;
  }[];
  children: React.ReactNode;
  withFooter?: boolean;
  loading?: boolean;
}
const ContentLayout = (props: ContentLayoutProps) => {
  const { BreadcrumbItems = [], children, withFooter, loading } = props;

  return (
    <>
      <Content style={{ margin: '0 16px' }}>
        {BreadcrumbItems && BreadcrumbItems.length > 0 && (
          <Breadcrumb style={{ margin: '16px 0' }}>
            {BreadcrumbItems.map(({ href, title }) => {
              return (
                <>
                  {href && (
                    <Breadcrumb.Item key={title + Math.floor(Math.random() * 10)} href={href}>
                      {title}
                    </Breadcrumb.Item>
                  )}
                  {!href && <Breadcrumb.Item key={title + Math.floor(Math.random() * 10)}>{title}</Breadcrumb.Item>}
                </>
              );
            })}
          </Breadcrumb>
        )}
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
            marginBottom: 50,
            justifyContent: loading ? 'center' : 'unset',
            alignItems: loading ? 'center' : 'unset',
            display: loading ? 'flex' : 'block',
          }}
        >
          {loading ? <Spin /> : children}
        </div>
      </Content>
      {withFooter ? <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> : false}
    </>
  );
};
export default ContentLayout;
