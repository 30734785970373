/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';
import { useHistory } from 'react-router-dom';

interface AppContextState {
  selectedHotelName: string;
  selectedHotelId: string;
  isLoading: boolean;
  adminRole?: string;
}

export interface AppContextValue {
  selectedHotelName?: string;
  selectedHotelId?: string;
  selectHotel: (hotelId: string, hotelName: string) => void;
  isLoading: boolean;
  adminRole: string;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AppContext = React.createContext<AppContextValue>({} as never);

export default function AppContextProvider({ children }) {
  const history = useHistory();

  const [{ isLoading, selectedHotelId, adminRole, selectedHotelName }, updateState] = useState<AppContextState>({
    isLoading: false,
    selectedHotelId: localStorage.getItem('selectedHotelId'),
    selectedHotelName: localStorage.getItem('selectedHotelName'),
    adminRole: null,
  });
  const { addError } = useNotif();

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const selectHotel = (hotelId: string, hotelName: string) => {
    try {
      if (hotelId && hotelName) {
        localStorage.setItem('selectedHotelName', hotelName);
        localStorage.setItem('selectedHotelId', hotelId);
      } else {
        localStorage.removeItem('selectedHotelId');
        localStorage.removeItem('selectedHotelName');
      }
      setLoading(true);

      updateState((prevState) => ({
        ...prevState,
        selectedHotelId: hotelId,
        selectedHotelName: hotelName,
      }));
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);

      if (window.location.pathname === '/') {
        history.push(`/tenants`);
      }
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AppContext.Provider value={{ isLoading, selectedHotelId, selectedHotelName, selectHotel, adminRole }}>
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => React.useContext(AppContext);
