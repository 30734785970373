import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout';
import { Button, Table, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useSWR from 'swr';
import { tenantService } from '@services/tenant';
import { Link } from 'react-router-dom';
import { getColumnSearchProps } from '@utils/table-utils';
import './ListTenant.scss';

export default function ListTenant() {
  const history = useHistory();
  const { Title } = Typography;
  const { page, perPage, name, username, dbname, domain } = queryString.parse(window.location.search);
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
  const { data: listTenant, isValidating: loadingListTenant } = useSWR(
    [
      {
        page,
        perPage,
        name,
        username,
        dbname,
        domain,
      },
      'getListTenant',
    ],
    tenantService.getListTenant
  );

  const onChangeTable = (_pagination, filters) => {
    // Filter name
    if (filters.name) {
      urlParams.set('name', filters?.name[0].toString());
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    } else {
      urlParams.delete('name');
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    }

    // Filter username
    if (filters.username) {
      urlParams.set('username', filters?.username[0].toString());
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    } else {
      urlParams.delete('username');
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    }

    // Filter dbname
    if (filters.dbname) {
      urlParams.set('dbname', filters?.dbname[0].toString());
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    } else {
      urlParams.delete('dbname');
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    }

    // Filter dbname
    if (filters.domains) {
      urlParams.set('domain', filters?.domains[0].toString());
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    } else {
      urlParams.delete('domain');
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Domains',
      key: 'domains',
      ...getColumnSearchProps('domains'),
      render: (data) => (
        <div>
          <ul className="list-domain">
            {data?.domains.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      title: 'Database Name',
      key: 'dbname',
      dataIndex: 'dbName',
      ...getColumnSearchProps('dbname'),
    },
    {
      title: 'ID Tenant',
      key: 'id',
      dataIndex: '_id',
    },
    {
      title: 'Action',
      render: (data) => <Link to={`/tenants/${data._id}`}>Detail</Link>,
    },
  ];

  return (
    <ContentHelmet title="Tenant">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: '/tenants', title: 'Tenants' },
        ]}
      >
        <div className="header-wrapper">
          <Title level={4}>Tenants</Title>
          <Button type="primary" onClick={() => history.push('/add-tenant')}>
            Add New Tenant
          </Button>
        </div>
        <div style={{ border: 'none !important', margin: 20, marginTop: 0 }}>
          <Table
            loading={loadingListTenant}
            columns={columns}
            dataSource={listTenant?.data}
            scroll={{ x: '100vh' }}
            onChange={onChangeTable}
            pagination={{
              current: Number(page || 1),
              total: listTenant?.metadata?.totalData,
              defaultPageSize: 10,
              pageSize: Number(perPage || 10),
              showSizeChanger: true,
              onShowSizeChange: (_current, pageSize) => {
                urlParams.set('perPage', pageSize.toString());
                history.replace(`${history.location.pathname}?${urlParams.toString()}`);
              },
              onChange: (tablePage) => {
                urlParams.set('page', tablePage.toString());
                history.replace(`${history.location.pathname}?${urlParams.toString()}`);
              },
              pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            }}
          />
        </div>
      </ContentLayout>
    </ContentHelmet>
  );
}
