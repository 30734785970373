import AppstoreFilled from '@ant-design/icons/AppstoreFilled';

export interface MenuInterface {
  title: string;
  linkTo?: string;
  key?: string;
  Icon?: React.ElementType;
  isParent?: boolean;
  action?: () => void;
  hidden?: boolean;
  subMenu?: Array<{
    title: string;
    linkTo: string;
    subKey?: string;
    Icon?: React.ElementType;
    isParent?: boolean;
    subMenu?: MenuInterface['subMenu'];
    action?: () => void;
    hidden?: boolean;
  }>;
}

export interface AppMenu extends Array<MenuInterface> {}

export const superAdminMenus: AppMenu = [
  {
    title: 'Tenants',
    linkTo: '/tenants',
    Icon: AppstoreFilled,
    key: '1',
  },
];
