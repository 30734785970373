import BadGateway from '@packages/BadGateway';
import AddTenant from '@packages/Tenants/Add';
import DetailTenant from '@packages/Tenants/Detail';
import ListTenant from '@packages/Tenants/List';
import React from 'react';
import { RouteComponentProps, RouteChildrenProps } from 'react-router-dom';
const Forbidden = React.lazy(() => import('@packages/Forbidden'));

export interface AppRoutes
  extends Array<{
    id: number;
    component?: React.ComponentType<RouteComponentProps<unknown>> | React.ComponentType<unknown>;
    render?: (props: RouteComponentProps<unknown>) => React.ReactNode;
    children?: ((props: RouteChildrenProps<unknown>) => React.ReactNode) | React.ReactNode;
    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
  }> {}

export const superAdminRoutes: AppRoutes = [
  {
    id: 1,
    path: '/tenants',
    component: ListTenant,
    exact: true,
  },
  {
    id: 2,
    path: '/tenants/:id',
    component: DetailTenant,
    exact: true,
  },
  {
    id: 3,
    path: '/add-tenant',
    component: AddTenant,
    exact: true,
  },
  {
    id: 4,
    path: '/forbidden',
    component: Forbidden,
    exact: true,
  },
  {
    id: 5,
    path: '/bad-gateway',
    component: BadGateway,
    exact: true,
  },
];
