import { ArrowLeftOutlined } from '@ant-design/icons';
import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout';
import { Button, Col, Divider, Form, Input, Row, Select, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './AddTenant.scss';
import { useNotif } from '@hooks/use-notif';
import { tenantService } from '@services/tenant';
import Dialog from '@components/Dialog';
import { TenantDto } from '@libs/dto/tenant';
import { capitalizeFirstLetter } from '@utils/text-formatter';

const { Option } = Select;

export default function AddTenant() {
  const [form] = Form.useForm();
  const history = useHistory();
  const { addSuccess } = useNotif();
  const [loading, setLoading] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorDomain, setErrorDomain] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkedUsername, setCheckedUsername] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [checkedSubdomain, setCheckedSubdomain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<TenantDto>(Object);
  const [selectedDomain, setSelectedDomain] = useState(process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev');

  const hideModal = () => {
    setShowModal(false);
    history.goBack();
  };

  const handleCheckUsername = async () => {
    if (form.getFieldValue('username')) {
      setCheckedUsername(false);
      setLoading(true);
      try {
        const response = await tenantService.checkUsername({ username: form.getFieldValue('username') });
        if (!response.data) {
          addSuccess('Username Available');
          form.setFieldsValue({ dbName: form.getFieldValue('username') });
          setLoading(false);
          setCheckedUsername(true);
          setErrorUsername(false);
        } else {
          setLoading(false);
          setErrorUsername(true);
          setCheckedUsername(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorDomain(true);
        setCheckedUsername(false);
      }
    }
  };

  const handleCheckSubdomain = async (val) => {
    if (form.getFieldValue('domains')) {
      setCheckedSubdomain(false);
      setLoading(true);
      try {
        const response = await tenantService.checkDomain({
          domain: `${form.getFieldValue('domains')}${val}`,
        });
        if (!response.data) {
          addSuccess('Domain Available');
          setLoading(false);
          setCheckedSubdomain(true);
          setErrorDomain(false);
          setIsDisable(false);
        } else {
          setLoading(false);
          setErrorDomain(true);
          setCheckedSubdomain(false);
          setIsDisable(true);
        }
      } catch (error) {
        setLoading(false);
        setCheckedSubdomain(false);
        setErrorDomain(true);
        setIsDisable(true);
      }
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <div>
        <img src={require('@assets/indonesia-flag.svg')} style={{ marginRight: 8 }} />
        +62
      </div>
    </Form.Item>
  );

  const phone = form.getFieldValue('phone');

  const verifyPhone = phone
    ? phone?.slice(0, 1) === '0'
      ? '+62' + phone?.slice(1, phone?.length)
      : phone?.slice(0, 2) === '62'
      ? phone
      : '+62' + phone
    : null;

  const handleAddTenant = async (val) => {
    setLoading(true);
    const data = {
      name: val.name,
      username: val.username,
      dbName: val.dbName,
      domains: [`${form.getFieldValue('domains')}${selectedDomain}`],
      admin: {
        fullName: val.fullName,
        email: val.email,
        phone: verifyPhone,
        address: val.address,
        city: val.city,
        gender: val.gender,
      },
    };
    try {
      const response = await tenantService.addNewTenant(data);
      setLoading(false);
      setShowModal(true);
      setData(response.data);
      addSuccess('Successfully Added New Tenant');
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data.data);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`
                Full Name: ${data?.admin?.fullName}
                Email: ${data?.admin?.email}
                Password: ${data?.admin?.password}
                Phone Number: ${data?.admin?.phone}
                Gender: ${data?.admin?.gender === 'L' ? 'Male' : 'Female'}
                City: ${data?.admin?.city}
                Address: ${data?.admin?.address}
                `);
    addSuccess('Data Admin Copied');
  };

  useEffect(() => {
    if (errorMessage) {
      form.validateFields();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (form.getFieldValue('username')) {
      form.validateFields(['username']);
    }
  }, [errorUsername]);

  useEffect(() => {
    if (form.getFieldValue('domains')) {
      form.validateFields(['domains']);
    }
  }, [errorDomain]);

  const handleChange = (newValue: string) => {
    setSelectedDomain(newValue);
    handleCheckSubdomain(newValue);
    setErrorDomain(false);
  };

  const selectAfter = (
    <Select defaultValue={selectedDomain} className="select-after" onChange={handleChange}>
      <Option value={process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev'}>
        {process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev'}
      </Option>
      <Option value={process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev'}>
        {process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev'}
      </Option>
    </Select>
  );

  return (
    <ContentHelmet title="Add Tenant">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: '/tenants', title: 'Tenants' },
          { href: `/add-tenant`, title: 'Add Tenant' },
        ]}
      >
        <Spin spinning={loading}>
          <Space direction="vertical" size="small" className="full-width">
            <div className="detail-header">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => history.push('/tenants')}>
                  <ArrowLeftOutlined />
                  <Typography style={{ marginLeft: 16, fontSize: 16, fontWeight: 600 }}>Add New Tenant</Typography>
                </div>
              </div>
            </div>
            <Divider type="horizontal" style={{ margin: 0 }} />

            <Form
              layout="vertical"
              form={form}
              size="middle"
              className="form-container"
              onFinish={(val) => handleAddTenant(val)}
              onValuesChange={(changedValue) => {
                if (Object.keys(changedValue)[0] === 'username') {
                  setCheckedUsername(false);
                  setErrorUsername(false);
                }
                if (Object.keys(changedValue)[0] === 'domains') {
                  setCheckedSubdomain(false);
                  setErrorDomain(false);
                }
                setErrorMessage(null);
              }}
            >
              <Row gutter={78}>
                <Col span={12}>
                  <Typography className="title-form">Tenant Details</Typography>
                  <Form.Item
                    label="Tenant Name"
                    name="name"
                    rules={[
                      { required: true, message: 'Tenant name is required.' },
                      {
                        validator(_) {
                          if (errorMessage?.name?.isNotEmpty) {
                            return Promise.reject(new Error(capitalizeFirstLetter(errorMessage?.name?.isNotEmpty)));
                          }
                          if (errorMessage?.name?.minLength) {
                            return Promise.reject(new Error(capitalizeFirstLetter(errorMessage?.name?.minLength)));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Input your tenant name" />
                  </Form.Item>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      { required: true, message: 'Username is required.' },
                      {
                        validator(_) {
                          if (errorMessage?.username?.isUsername) {
                            return Promise.reject(new Error(capitalizeFirstLetter(errorMessage?.username?.isUsername)));
                          }
                          if (errorUsername) {
                            return Promise.reject(new Error('Username not available'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Input username for your tenant" maxLength={50} onBlur={handleCheckUsername} />
                  </Form.Item>
                  <Form.Item
                    label="Database Name"
                    name="dbName"
                    rules={[
                      { required: true, message: 'Database name is required' },
                      {
                        validator(_) {
                          if (errorMessage?.dbName?.isUsername) {
                            return Promise.reject(new Error(capitalizeFirstLetter(errorMessage?.dbName?.isUsername)));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Create name for database" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Typography className="title-form">Admin Details</Typography>
                  <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[
                      { required: true, message: 'Full name is required' },
                      {
                        validator(_) {
                          if (errorMessage?.['admin.fullName']?.minLength) {
                            return Promise.reject(
                              new Error(capitalizeFirstLetter(errorMessage?.['admin.fullName']?.minLength))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Input your full name" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      { required: true, message: 'Email is required.' },
                    ]}
                  >
                    <Input placeholder="Input your valid email" />
                  </Form.Item>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      { required: true, message: 'Phone number name is required' },
                      {
                        validator(_) {
                          if (errorMessage?.['admin.phone']?.isPhoneNumber) {
                            return Promise.reject(
                              new Error(capitalizeFirstLetter(errorMessage?.['admin.phone']?.isPhoneNumber))
                            );
                          }
                          if (errorMessage?.['admin.phone']?.isString) {
                            return Promise.reject(
                              new Error(capitalizeFirstLetter(errorMessage?.['admin.phone']?.isString))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      style={{ width: '100%' }}
                      onChange={(event) => {
                        let pattern = /^$|^[0-9]+$/;
                        if (pattern.test(event.target.value)) {
                          form.setFieldsValue({
                            phone: event.target.value,
                          });
                        } else {
                          form.setFieldsValue({
                            phone: event.target.value.slice(0, -1),
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Gender is required' }]}>
                    <Select placeholder="Select your gender">
                      <Option value="L">Male</Option>
                      <Option value="P">Female</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      { required: true, message: 'City is required' },
                      {
                        validator(_) {
                          if (errorMessage?.['admin.city']?.minLength) {
                            return Promise.reject(
                              new Error(capitalizeFirstLetter(errorMessage?.['admin.city']?.minLength))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Input your city" />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      { required: true, message: 'Address is required' },
                      {
                        validator(_) {
                          if (errorMessage?.['admin.address']?.minLength) {
                            return Promise.reject(
                              new Error(capitalizeFirstLetter(errorMessage?.['admin.address']?.minLength))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Input your address" />
                  </Form.Item>
                </Col>
              </Row>
              <Divider type="horizontal" style={{ margin: 0, marginTop: 40 }} />
              <Typography className="title-form" style={{ marginTop: 24 }}>
                Custom Domains
              </Typography>
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={24}>
                  <Form.Item
                    label="Domain"
                    name="domains"
                    rules={[
                      { required: true, message: 'Domain is required.' },
                      {
                        validator(_, value) {
                          if (errorDomain) {
                            return Promise.reject(new Error('Domain not available'));
                          }
                          if (!value.match(/^(?!^[-])(?!.*[-]$)(?!.*[-]{2})[a-z0-9-]{1,64}$/)) {
                            setIsDisable(true);
                            return Promise.reject(new Error('Please input valid domain name.'));
                          }
                          setIsDisable(false);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Create your domain name"
                      maxLength={50}
                      onBlur={() => {
                        if (!isDisable) {
                          handleCheckSubdomain(selectedDomain);
                        }
                      }}
                      addonAfter={selectAfter}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: 'end' }}>
                <Button
                  id="button-save"
                  type="primary"
                  htmlType="submit"
                  disabled={errorDomain || errorUsername || !checkedUsername || !checkedSubdomain || isDisable}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Space>
          <Dialog
            open={showModal}
            onOk={hideModal}
            okText="Yes, I saved it"
            content={
              <div className="modal">
                <div className="modal-title-wrapper">
                  <img src={require('@assets/question-icon.svg')} />
                  <Typography className="modal-title">Did you save your admin details?</Typography>
                </div>
                <div className="list-requirement">
                  <ul>
                    <li>Save them in secure location</li>
                    <li>Remember the location you were saved it</li>
                    <li>DO NOT store them in public or shared docs</li>
                  </ul>
                </div>
                <div className="data-wrapper">
                  <div className="copy-wrapper">
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>Admin</Typography>
                    <img src={require('@assets/copy-icon.svg')} style={{ cursor: 'pointer' }} onClick={handleCopy} />
                  </div>
                  <Row gutter={16}>
                    <Col span={10}>Full Name</Col>
                    <Col span={14}>: {data?.admin?.fullName}</Col>
                    <Col span={10}>Email</Col>
                    <Col span={14}>: {data?.admin?.email}</Col>
                    <Col span={10}>Password</Col>
                    <Col span={14}>: {data?.admin?.password}</Col>
                    <Col span={10}>Phone Number</Col>
                    <Col span={14}>: {data?.admin?.phone}</Col>
                    <Col span={10}>Gender</Col>
                    <Col span={14}>: {data?.admin?.gender === 'L' ? 'Male' : 'Female'}</Col>
                    <Col span={10}>City</Col>
                    <Col span={14}>: {data?.admin?.city}</Col>
                    <Col span={10}>Address</Col>
                    <Col span={14}>: {data?.admin?.address}</Col>
                  </Row>
                </div>
              </div>
            }
          />
        </Spin>
      </ContentLayout>
    </ContentHelmet>
  );
}
