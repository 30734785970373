import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout';
import { useNotif } from '@hooks/use-notif';
import { tenantService } from '@services/tenant';
import { capitalizeFirstLetter } from '@utils/text-formatter';
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Space, Spin, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import './DetailTenant.scss';

const { confirm } = Modal;
const { Option } = Select;

export default function DetailTenant() {
  const [form] = Form.useForm();
  const [usernameForm] = Form.useForm();
  const { id: id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addSuccess, addError } = useNotif();
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [errorDomain, setErrorDomain] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [checkedSubdomain, setCheckedSubdomain] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev');

  const { data: detailTenant, isValidating: loadingDetailTenant, mutate: refetchDetailTenant } = useSWR(
    [id, 'getDetailTenant'],
    tenantService.getDetailTenant
  );

  useEffect(() => {
    if (detailTenant) {
      const val = detailTenant?.data;
      usernameForm.setFieldsValue({
        name: val.name,
      });
    }
  }, [detailTenant]);

  const onReset = () => {
    form.resetFields();
    setShowInput(false);
  };

  const handleAddSubdomain = async (val) => {
    setLoading(true);
    const domains = detailTenant?.data?.domains;
    domains.push(`${val.domains}${selectedDomain}`);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: domains,
      });
      addSuccess('Successfully Added New Custom Domains!');
      onReset();
      setLoading(false);
      refetchDetailTenant();
    } catch (error) {
      setLoading(false);
      addError(capitalizeFirstLetter(error.response.data.data.domains.isFqdn));
    }
  };

  const handleEditTenant = async (val) => {
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: val.name,
        domains: detailTenant?.data?.domains,
      });
      refetchDetailTenant();
      addSuccess('Successfully Edit Tenant!');
      setLoading(false);
    } catch (error) {
      addError(capitalizeFirstLetter(error.response.data.data.domains.isFqdn));
      setLoading(false);
    }
  };

  const handleDeleteSubdomain = async (val) => {
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: val,
      });
      refetchDetailTenant();
      addSuccess('Successfully Delete Domain!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addError(capitalizeFirstLetter(error.response.data.data.domains.isFqdn));
    }
  };

  const handleEditSubdomain = async (val) => {
    const domains = detailTenant?.data?.domains;
    let index = domains.indexOf(selectedSubdomain);
    if (~index) {
      domains[index] = `${val.domains}${selectedDomain}`;
    }
    setLoading(true);
    try {
      await tenantService.updateTenant(id, {
        name: detailTenant?.data?.name,
        domains: domains,
      });
      onReset();
      refetchDetailTenant();
      addSuccess('Successfully Edit Domain!');
      setLoading(false);
      setSelectedSubdomain(null);
    } catch (error) {
      setLoading(false);
      addError(capitalizeFirstLetter(error.response.data.data.domains.isFqdn));
    }
  };

  const handleCheckSubdomain = async (val) => {
    if (form.getFieldValue('domains')) {
      setCheckedSubdomain(false);
      setLoading(true);
      try {
        const response = await tenantService.checkDomain({
          domain: `${form.getFieldValue('domains')}${val}`,
        });
        if (!response.data) {
          addSuccess('Domain Available');
          setLoading(false);
          setCheckedSubdomain(true);
          setErrorDomain(false);
          setIsDisable(false);
        } else {
          setLoading(false);
          setCheckedSubdomain(false);
          setErrorDomain(true);
          setIsDisable(true);
        }
      } catch (error) {
        setErrorDomain(true);
        setLoading(false);
        setCheckedSubdomain(false);
        setIsDisable(true);
      }
    }
  };

  const showConfirm = (val) => {
    let domains = detailTenant?.data?.domains;
    domains = domains.filter((domain) => domain !== val);
    confirm({
      title: 'Are you sure want to delete domain?',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: `${val} will be deleted. This process cannot be undone.`,
      onOk() {
        handleDeleteSubdomain(domains);
      },
    });
  };

  const handleReplaceDomain = (val) => {
    if (val.includes(process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev')) {
      let domain = val.replace(process.env._REACT_APP_DOMAIN_PWA || '.booking.pesan.dev', '');
      form.setFieldsValue({ domains: domain });
      setSelectedDomain(process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev');
      return;
    }
    if (val.includes(process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev')) {
      let domain = val.replace(process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev', '');
      form.setFieldsValue({ domains: domain });
      setSelectedDomain(process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev');
      return;
    }
  };

  const gender = () => {
    if (detailTenant?.data?.admin?.gender) {
      if (detailTenant?.data?.admin?.gender === 'L') {
        return 'Male';
      } else {
        return 'Female';
      }
    } else {
      return '-';
    }
  };

  const handleChange = (newValue: string) => {
    setSelectedDomain(newValue);
    handleCheckSubdomain(newValue);
    setErrorDomain(false);
  };

  useEffect(() => {
    form.validateFields();
  }, [errorDomain]);

  const selectAfter = (
    <Select defaultValue={selectedDomain} className="select-after" onChange={handleChange}>
      <Option value={process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev'}>
        {process.env.REACT_APP_DOMAIN_PWA || '.booking.pesan.dev'}
      </Option>
      <Option value={process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev'}>
        {process.env.REACT_APP_DOMAIN_DASHBOARD || '.dashboard.pesan.dev'}
      </Option>
    </Select>
  );

  return (
    <ContentHelmet title="Tenant Detail">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: '/tenants', title: 'Tenants' },
          { href: `/tenants/${id}`, title: 'Tenant Detail' },
        ]}
      >
        <Spin spinning={loadingDetailTenant || loading}>
          <Space direction="vertical" size="small" className="full-width">
            <div className="detail-header">
              <ArrowLeftOutlined onClick={() => history.push('/tenants')} />
              <Typography style={{ marginLeft: 16, fontSize: 20, fontWeight: 600 }}>
                {detailTenant?.data?.name}
              </Typography>
            </div>
            <Divider type="horizontal" style={{ margin: 0 }} />
            <Row>
              <Col span={12}>
                <Card title="Detail Tenant" className="detail-tenant">
                  <Row gutter={[8, 16]}>
                    <Col span={10}>
                      <Typography className="detail-key" style={{ marginTop: 5 }}>
                        <span style={{ color: 'red' }}>*</span>
                        Tenant Name
                      </Typography>
                    </Col>
                    <Col span={14}>
                      <Form form={usernameForm}>
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Tenant name is required.',
                            },
                          ]}
                          className="form-item"
                        >
                          <Input
                            placeholder="Input your tenant tame"
                            onChange={(event) => {
                              var value = event.target.value;
                              while (value.charAt(0) === ' ') {
                                value = value.substring(1);
                              }
                              usernameForm.setFieldsValue({
                                name: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Form>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Username</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.username}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Database Name</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.dbName}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">ID Tenant</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?._id}</Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Detail Admin Tenant" className="detail-admin-tenant">
                  <Row gutter={[8, 16]}>
                    <Col span={10}>
                      <Typography className="detail-key">Full Name</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.admin?.fullName || '-'}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Email</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.admin?.email || '-'}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Phone Number</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.admin?.phone || '-'}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Gender</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{gender()}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">City</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.admin?.city || '-'}</Typography>
                    </Col>
                    <Col span={10}>
                      <Typography className="detail-key">Address</Typography>
                    </Col>
                    <Col span={14}>
                      <Typography>{detailTenant?.data?.admin?.address || '-'}</Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Card className="site-card-wrapper" title="Custom Domain">
              <Row gutter={[8, 16]}>
                <Col span={4}>
                  <Typography className="detail-key">Domains</Typography>
                </Col>
                <Col span={14} style={{ marginLeft: 32 }}>
                  {detailTenant?.data?.domains.map((item, index) => (
                    <div key={index}>
                      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
                        <Col span={10}>
                          <Typography>{item}</Typography>
                        </Col>
                        <Col span={6}>
                          <Button
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => {
                              setShowInput(true);
                              setSelectedSubdomain(item);
                              handleReplaceDomain(item);
                              setCheckedSubdomain(false);
                            }}
                          >
                            Edit
                          </Button>
                          {detailTenant?.data?.domains.length > 1 && (
                            <Button
                              type="primary"
                              danger
                              onClick={() => {
                                showConfirm(item);
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Col>
              </Row>
              {showInput ? (
                <div style={{ marginTop: 24 }}>
                  <Form
                    layout="vertical"
                    form={form}
                    size="middle"
                    onFinish={(val) => {
                      if (selectedSubdomain) {
                        handleEditSubdomain(val);
                      } else {
                        handleAddSubdomain(val);
                      }
                    }}
                    onValuesChange={() => {
                      setCheckedSubdomain(false);
                      setErrorDomain(false);
                    }}
                  >
                    <Row className="align-center">
                      <Col span={12}>
                        <Form.Item
                          name="domains"
                          rules={[
                            { required: true, message: 'Domain is required.' },
                            {
                              validator(_, value) {
                                if (errorDomain) {
                                  return Promise.reject(new Error('Domain not available'));
                                }
                                if (!value.match(/^(?!^[-])(?!.*[-]$)(?!.*[-]{2})[a-z0-9-]{1,64}$/)) {
                                  setIsDisable(true);
                                  return Promise.reject(new Error('Please input valid domain name.'));
                                }
                                setIsDisable(false);
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Create your domain name"
                            maxLength={50}
                            onBlur={() => {
                              if (!isDisable) {
                                handleCheckSubdomain(selectedDomain);
                              }
                            }}
                            addonAfter={selectAfter}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={errorDomain || !checkedSubdomain || isDisable}>
                        Save
                      </Button>
                      <Button htmlType="button" onClick={onReset} style={{ marginLeft: 16 }} type="primary" ghost>
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              ) : (
                <Button
                  type="primary"
                  style={{ marginTop: 32 }}
                  onClick={() => {
                    setShowInput(true);
                    setCheckedSubdomain(false);
                    setSelectedSubdomain(null);
                  }}
                >
                  Add Custom Domain
                </Button>
              )}
            </Card>
          </Space>
          <div className="button-wrapper">
            <Form
              form={usernameForm}
              onFinish={() => {
                handleEditTenant(usernameForm.getFieldsValue());
              }}
            >
              <Form.Item>
                <Button type="primary" id="button-save" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </ContentLayout>
    </ContentHelmet>
  );
}
