import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export interface DialogProps {
  title?: string;
  open: boolean;
  onOk: () => void;
  onCancel?: () => void;
  content: React.ReactNode | string;
  okText?: string;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  disabled?: boolean;
  style?: string;
}

const Dialog: React.FC<DialogProps> = ({
  title,
  open,
  onOk,
  onCancel,
  content,
  okText,
  cancelText,
  okButtonProps = {},
  cancelButtonProps = {},
  disabled,
}) => {
  return (
    <Modal
      centered
      title={title}
      visible={open}
      onOk={onOk}
      onCancel={onCancel}
      closable={false}
      footer={[
        cancelText && (
          <Button key="back" onClick={onCancel} {...cancelButtonProps}>
            {cancelText ?? 'Cancel'}
          </Button>
        ),
        <Button key="submit" type="primary" onClick={onOk} {...okButtonProps} disabled={disabled}>
          {okText ?? 'Ok'}
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

export default Dialog;
