import Axios, { AxiosRequestConfig } from 'axios';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2 || 'https://api-tenant-manager.dev.pesan.io',
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data.meta?.error_code === 'MARKETPLACE_INSUFFICIENT_BALANCE_ERROR') {
      return Promise.reject(error);
    }

    // eslint-disable-next-line no-console
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response?.status === 403) {
      if (window.location.pathname !== '/forbidden') {
        window.location.href = '/forbidden';
      }
    } else if (error.response?.status === 502) {
      window.location.href = '/bad-gateway';
    }
    console.log('Error at interceptor  :', { ...error });
    // eslint-disable-next-line no-alert
    return Promise.reject(error);
  }
);
