import { axiosInstance } from '@config/axios-instance-v2';

import { AdminSignInDto } from '@libs/dto/admin';
import { AxiosResponse } from 'axios';

export const authService = {
  async signIn(credential: AdminSignInDto) {
    const response: AxiosResponse = await axiosInstance.post(`/auth/loginWithUsernamePassword`, credential);
    return response.data;
  },
  async getProfileAdmin(credential) {
    const response: AxiosResponse = await axiosInstance.get(`/auth/me`, credential);
    return response.data;
  },
};

export type AuthService = typeof authService;
