import React from 'react';
import { useApp } from '@context/app-context';
import { AppMenu } from '@constants/app-menus';

export const useMenus = (superAdminMenus: AppMenu) => {
  const { selectedHotelId } = useApp();
  const [menus, setMenus] = React.useState<AppMenu>([]);

  React.useEffect(() => {
    const mapping = (data: AppMenu) => {
      const newData = data.map((item) => {
        if (item.linkTo) {
          const newItem = { ...item, linkTo: item.linkTo.replace(':id', selectedHotelId) };
          return newItem;
        } else if (item.subMenu) {
          const newItem = {
            ...item,
            subMenu: item.subMenu.map((sub) => {
              return { ...sub, linkTo: sub.linkTo.replace(':id', selectedHotelId) };
            }),
          };
          return newItem;
        } else {
          return item;
        }
      });
      return newData;
    };

    let sidebarMenus = mapping(superAdminMenus);

    setMenus(sidebarMenus);
  }, [selectedHotelId, superAdminMenus]);

  return menus;
};
