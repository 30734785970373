/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { AdminSignInDto } from '@libs/dto/admin';
import { authService } from '@services/auth';
import { useHistory } from 'react-router-dom';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';

interface AuthContextState {
  isSubmitted: boolean;
  isLoading: boolean;
  identity: AdminSignInDto | null;
  token: string;
  isError: boolean;
}

export interface AuthContextValue {
  identity: AdminSignInDto | null;
  signIn(credential: AdminSignInDto): void;
  signOut(): void;
  isLoading: boolean;
  token: string;
  isSubmitted: boolean;
  isError: boolean;
  setIdentity;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AuthContext = React.createContext<AuthContextValue>({} as never);

export default function AuthContextProvider({ children }) {
  const { addError } = useNotif();
  const [{ identity, isLoading, token, isSubmitted, isError }, updateState] = useState<AuthContextState>({
    isLoading: true,
    identity: null,
    token: localStorage.getItem('token'),
    isSubmitted: false,
    isError: false,
  });

  const history = useHistory();

  const setToken = (_token: string) => {
    updateState((prevState) => ({
      ...prevState,
      token: _token,
    }));
  };

  const setIdentity = (profile: AdminSignInDto | null) => {
    updateState((prevState) => ({
      ...prevState,
      identity: profile,
    }));
  };

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const setSubmit = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isSubmitted: state,
    }));
  };

  const setError = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isError: state,
    }));
  };

  const signIn = async (credential: AdminSignInDto) => {
    setSubmit(true);
    try {
      const response = await authService.signIn(credential);
      const token = response.data.accessToken;
      localStorage.setItem('token', token);
      localStorage.setItem('identity', JSON.stringify(credential));
      setToken(token);
      setSubmit(false);
      window.location.href = '/tenants';
    } catch (error) {
      setSubmit(false);
      if (error) {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  const signOut = () => {
    localStorage.clear();
    setToken(null);
    setIdentity(null);
    history.replace('/');
  };

  useEffect(() => {
    async function loadUserData() {
      try {
        const adminToken = localStorage.getItem('token');
        const identity = JSON.parse(localStorage.getItem('identity'));
        if (adminToken) {
          setToken(adminToken);
          setIdentity(identity);
        } else {
          setToken(null);
          setIdentity(null);
          history.replace('/');
        }
      } catch (error) {
        addError(error);
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    }

    loadUserData();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ identity, signIn, signOut, isLoading, token, isSubmitted, isError, setIdentity }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => React.useContext(AuthContext);
