import React from 'react';
import { Menu, Layout } from 'antd';
import { useAuth } from '@context/auth-context';
import './Header.scss';
import { Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const { SubMenu, Item } = Menu;
export default function Header() {
  const { signOut, identity } = useAuth();

  const handleClickMenu = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e.key === 'SignOut' && signOut();
  };

  return (
    <Layout.Header className={'site-layout-background'}>
      <div className="right-container">
        <Menu key="user" mode="horizontal" onClick={handleClickMenu} style={{ marginLeft: 24 }}>
          <SubMenu
            title={
              <span className="right-sub-menu-title">
                <Avatar icon={<UserOutlined />} />
                <span className="user-name">
                  <span className="user-name">
                    {identity?.name || 'Guest'} <DownOutlined style={{ marginLeft: 16 }} />
                  </span>
                </span>
              </span>
            }
          >
            <Item key="SignOut">
              <p>Sign out</p>
            </Item>
          </SubMenu>
        </Menu>
      </div>
    </Layout.Header>
  );
}
