import React from 'react';
import { Helmet } from 'react-helmet';

export interface ContentHelmetProps {
  title: string;
  description?: string;
}

const ContentHelmet: React.FC<ContentHelmetProps> = ({ title, description, children }) => {
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Admin';

  return (
    <>
      <Helmet>
        <title>{`${title} - ${baseSiteName}` || ''}</title>
        <meta name="description" content={description || `${title} Page`} />
      </Helmet>
      {children}
    </>
  );
};

export default ContentHelmet;
