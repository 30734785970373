import React, { useEffect } from 'react';
import Sidebar from '@components/Sidebar/Sidebar';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { superAdminRoutes, AppRoutes } from '@constants/app-routes';
import { superAdminMenus, AppMenu } from '@constants/app-menus';
import { useAuth } from '@context/auth-context';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { useMenus } from '@hooks/use-menus';
import * as Sentry from '@sentry/react';
import useSWR from 'swr';
import { authService } from '@services/auth';
const SentryRoute = Sentry.withSentryRouting(Route);

const Login = React.lazy(() => import('@packages/Login'));

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

function App() {
  const { token, identity, setIdentity } = useAuth();
  const routes: AppRoutes = superAdminRoutes;

  const { data: profileAdmin } = useSWR(
    !identity?.name && token ? [identity, 'getProfileAdmin'] : null,
    authService.getProfileAdmin
  );

  useEffect(() => {
    if (profileAdmin) {
      setIdentity(profileAdmin.data);
      localStorage.setItem('identity', JSON.stringify(profileAdmin.data));
    }
  }, [profileAdmin]);

  const sidebarMenus: AppMenu = useMenus(superAdminMenus);
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Admin';
  return (
    <>
      {token ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Helmet>
            <title>{baseSiteName}</title>
          </Helmet>
          <Sidebar menus={sidebarMenus} />
          <Layout className="site-layout">
            <Header />
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <SentryRoute {...route} key={route.id} />
                ))}
              </Switch>
            </React.Suspense>
          </Layout>
        </Layout>
      ) : (
        <React.Fragment>
          <Helmet>
            <title>{baseSiteName}</title>
          </Helmet>
          <React.Suspense fallback={<Spinner />}>
            <Login />
          </React.Suspense>
        </React.Fragment>
      )}
    </>
  );
}

export default withRouter(App);
